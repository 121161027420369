import { Ref, ref } from 'vue';

export type FileUpload = {
  id: string;
  name: string;
  file: File,
  objectUrl: string;
  error: string | null;
};

type Props = {
  fileUploadFunc: (fileUpload: FileUpload) => Promise<any>;
  fileDeleteFunc: (id: string) => Promise<any>;
};

export default ({
  fileUploadFunc,
  fileDeleteFunc,
}: Props) => {
  const fileUploads: Ref<FileUpload[]> = ref([]);

  async function uploadFile(fileUpload: FileUpload) {
    fileUploadFunc(fileUpload).then(() => {
      fileUploads.value = [fileUpload, ...fileUploads.value];
    });
  }

  function uploadFiles(newFileUploads: FileUpload[]) {
    Promise.all(newFileUploads.map((file) => uploadFile(file)));
  }

  function deleteFileUpload(id: string) {
    fileDeleteFunc(id).then(() => {
      fileUploads.value = fileUploads.value.filter((fileUpload) => fileUpload.id !== id);
    });
  }

  function updateFileUploads(newFileUploads: FileUpload[]) {
    fileUploads.value = newFileUploads;
  }

  return {
    uploadFiles,
    fileUploads,
    deleteFileUpload,
    updateFileUploads,
  };
};
